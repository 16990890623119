export const localToken = 'ponedilok-specialist-token';

export const instagram = 'https://www.instagram.com/tareus_org/';
export const telegramChanel = 'https://t.me/tareus_org';
export const chatForConsultants = 'https://t.me/tareusconsultant';
export const tiktok = 'https://www.tiktok.com/@tareus.org';
export const facebook = 'https://www.facebook.com/tareus.org';
export const youtube = 'https://www.youtube.com/@tareushoroscope';
export const aboutUs = 'https://tareus.org/about-us/';
export const contacts = 'https://tareus.org/contacts/';

export const urlPattern = new RegExp('^(https?|ftp):\\/\\/[^\\s/$.?#].[^\\s]*$');
// export const videoUrlsPattern = new RegExp('^(https:\\/\\/vimeo\\.com\\/\\d+|.*((https:\\/\\/youtu.be\\/)|(v\\/)|(\\/u\\/\\w\\/)|(embed\\/)|(watch\\?))\\??v?=?([^#\&\?]*).+$');

export const videoUrlsPattern = /^https:\/\/vimeo\.com\/\d+|.*((https:\/\/youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\\&\\?]*).+/;
export const dateMomentFormat = 'DD.MM.YYYY';
export const dateDoctorFormat = 'YYYY.MM.DD';
export const CUSTOM_FORMATS = {
  parse: {
    dateInput: 'LL'
  },
  display: {
    dateInput: dateMomentFormat,
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  }
};
export const homePageToggleStateName = 'home-page-toggle-state';
