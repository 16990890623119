import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {firstValueFrom, Subject} from 'rxjs';
import {environment} from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AiGenerationService {
  showSpinner$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private http: HttpClient,
  ) {
  }

  show() {
    this.showSpinner$.next(true);
  }

  hide() {
    this.showSpinner$.next(false);
  }

  sendText(data) {
    return firstValueFrom(
      this.http.post(`${environment.apiUrl}/web-speech-messages`, data)
    );
  }
}
