import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, firstValueFrom, Observable, Subject} from 'rxjs';
import {map} from 'rxjs/operators';

import {JsonaService} from '../../../core/services/jsona.service';
import {environment} from '../../../../environments/environment';
import {AppointmentResultModel} from '@consultations/interfaces/consultation.interface';
import {RecommendationTypeEnum} from '../enums/recommendation-type.enum';
import {DeserializedJsonInterface} from '@core/interfaces/jsona.interface';
import {getObjectUrlParams} from '@core/utils/filter-converter';
import {buildFormData} from '@core/utils/build-form-data';

@Injectable({
  providedIn: 'root'
})
export class ResultsService {
  appointment$ = new BehaviorSubject<AppointmentResultModel | null>(null);
  aiSummary$ = new Subject<string>();

  private apiUrl = `${environment.apiUrl}/consultations/result`;

  constructor(
    private http: HttpClient,
    private jsona: JsonaService
  ) {
  }

  getResultsList(filter: any): Promise<DeserializedJsonInterface<AppointmentResultModel[]>> {
    const params = getObjectUrlParams(filter);
    return firstValueFrom(
      this.http.get(`${this.apiUrl}`, {params})
        .pipe(
          map(res => this.jsona.deserialize<AppointmentResultModel[]>(res))
        )
    );
  }

  createConsultationResult(appointmentId: number): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}`, {appointmentId});
  }

  createUnattachedResult(patientId: number, type: RecommendationTypeEnum): Observable<AppointmentResultModel> {
    return this.http.post(`${this.apiUrl}`, {patientId, type})
      .pipe(
        map(res => this.jsona.deserialize<AppointmentResultModel>(res).data)
      );
  }

  getConsultationResultById(id: number): Observable<AppointmentResultModel> {
    return this.http.get(`${this.apiUrl}/${id}`)
      .pipe(
        map(res => this.jsona.deserialize<AppointmentResultModel>(res).data)
      );
  }

  updateConsultationResult(appointmentId: number, form: (Partial<AppointmentResultModel> | FormData)): Observable<AppointmentResultModel> {
    return this.http.patch(`${this.apiUrl}/${appointmentId}`, form)
      .pipe(
        map(res => this.jsona.deserialize<AppointmentResultModel>(res).data)
      );
  }

  createFormData(form, saveBeforeRedirect: boolean): FormData {
    const data = {
      ...form,
      links: form.links.length ? form.links.filter(el => el['link'].length).map(el => el['link']) : [],
      files: form.files.length ? form.files.map(d => d?.file?.lastModified ? d?.file : d.name) : [],
      exercises: form.exercises.length && !saveBeforeRedirect ? form.exercises.filter(el => el.type && el.frequency) : [],
    }

    return buildFormData(data);
  }
}
