import {AsyncPipe, NgOptimizedImage} from '@angular/common';
import {Component} from '@angular/core';
import {Observable} from 'rxjs';
import {TranslateModule} from '../../pipes/translate/translate.module';
import {AiGenerationService} from './ai-generation.service';

@Component({
  selector: 'app-ai-generation',
  standalone: true,
  imports: [
    TranslateModule,
    AsyncPipe
  ],
  templateUrl: './ai-generation.component.html',
  styleUrl: './ai-generation.component.scss'
})
export class AiGenerationComponent {
  showSpinner$: Observable<boolean>;

  constructor(
    private aiGenerationService: AiGenerationService
  ) {
    this.showSpinner$ = this.aiGenerationService.showSpinner$;
  }
}
