import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatBadgeModule} from '@angular/material/badge';
import {MatListModule} from '@angular/material/list';
import {GetHomePathPipe} from '@core/pipes/get-home-path.pipe';
import {NgSelectModule} from '@ng-select/ng-select';
import {ReactiveFormsModule} from '@angular/forms';
import {PdfViewerModule} from 'ng2-pdf-viewer';

import {
  LinkOrdersComponent
} from '@shared-modules/layouts/app-layout/components/link-orders/link-orders.component';
import {TranslateModule} from '../../../core/pipes/translate/translate.module';

import {AppLayoutComponent} from './app-layout.component';
import {HeaderComponent} from './components/header/header.component';
import {NotificationsModule} from '../../notifications/notifications.module';
import {SafeImageModule} from '../../safe-image/safe-image.module';
import {ClinicComponent} from './components/clinic/clinic.component';
import {ProfileComponent} from './components/profile/profile.component';
import {LogoComponent} from './components/logo/logo.component';
import {
  LinkConsultationsComponent
} from './components/link-consultations/link-consultations.component';
import {LinkScheduleComponent} from './components/link-schedule/link-schedule.component';
import {LinkFilesComponent} from './components/link-files/link-files.component';
import {LinkMyPatientsComponent} from './components/link-my-patients/link-my-patients.component';
import {LinkDialoguesComponent} from './components/link-dialogues/link-dialogues.component';
import {SidenavComponent} from './components/sidenav/sidenav.component';
import {
  LinkDataTemplatesComponent
} from './components/link-data-templates/link-data-templates.component';
import {AppsLinksComponent} from './components/apps-links/apps-links.component';
import {
  FooterComponent
} from '@shared-modules/layouts/app-layout/components/footer/footer.component';
import {LanguageSelectComponent} from './components/language-select/language-select.component';
import {ProfileFormBuilderService} from '@profile/services/profile-form-builder.service';
import {
  NearestConsultationComponent
} from './components/nearest-consultation/nearest-consultation.component';
import {FilePreviewComponent} from '@entry-components/file-preview/file-preview.component';
import {SpinnerModule} from '@shared-modules/spinner/spinner.module';
import {FooterInfoComponent} from './components/footer-info/footer-info.component';
import {
  WalletComponent
} from '@shared-modules/layouts/app-layout/components/wallet/wallet.component';
import {SafeModule} from '@core/pipes/safe/safe.module';
import {
  SidebarComponent
} from '@shared-modules/layouts/app-layout/components/sidebar/sidebar.component';
import {MatMenu, MatMenuItem, MatMenuTrigger} from '@angular/material/menu';
import {EmptyHeaderComponent} from '@shared-modules/layouts/app-layout/components/empty-header/empty-header.component';

@NgModule({
  declarations: [
    AppLayoutComponent,
    HeaderComponent,
    FooterComponent,
    WalletComponent,
    ClinicComponent,
    ProfileComponent,
    LogoComponent,
    LinkConsultationsComponent,
    LinkScheduleComponent,
    LinkFilesComponent,
    LinkMyPatientsComponent,
    LinkDialoguesComponent,
    SidenavComponent,
    LinkDataTemplatesComponent,
    AppsLinksComponent,
    LanguageSelectComponent,
    NearestConsultationComponent,
    FilePreviewComponent,
    FooterInfoComponent
  ],
  imports: [
    CommonModule,
    MatSidenavModule,
    RouterModule,
    MatListModule,
    SafeImageModule,
    NotificationsModule,
    MatButtonModule,
    MatIconModule,
    MatBadgeModule,
    TranslateModule,
    NgSelectModule,
    ReactiveFormsModule,
    SpinnerModule,
    PdfViewerModule,
    SafeModule,
    LinkOrdersComponent,
    SidebarComponent,
    MatMenu,
    MatMenuItem,
    MatMenuTrigger,
    EmptyHeaderComponent,
    GetHomePathPipe,
  ],
  exports: [
    AppLayoutComponent,
    LanguageSelectComponent,
    LogoComponent
  ],
  providers: [
    ProfileFormBuilderService
  ]
})
export class AppLayoutModule {
}
