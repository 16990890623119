@if (showSpinner$ | async) {
  <div class="wrap">
    <div class="ai-loader">
      <div class="img-wrap">
        <img src="/assets/images/ai_summary_image.svg" alt="">
      </div>
      <div class="text">
        <img class="mr-2" src="/assets/images/AI.svg" width="24" height="24" alt="">
        {{'Висновок ШІ створюється' | translate}}
      </div>
    </div>
  </div>
}
